import { defineBlock, InnerBlocks } from "eddev/blocks"

export const meta: BlockMeta = {
  title: "Home",
  tags: ["root"],
  conditions: {
    postTypes: ["page"],
  },
}

export default defineBlock("home/home", (props) => {
  return (
    <InnerBlocks
      template={[
        ["home/hero", null],
        ["home/heading", { title: "Here in New Zealand, we love being good at things on the world stage. But lately, we’ve been slipping down in some major rankings." }],
        ["home/rankings", null],
        ["home/heading", null],
        ["home/video", null],
        ["home/heading", null],
        // ["home/heading", { title: "With a little education, we can claim a title that nobody’s ever thought of." }],
        // ["home/research", null],
        ["home/percentage", null],
        ["home/heading", { title: "Knowledge is contagious. By lowering your stigma, you can raise our world ranking." }],
        ["home/course", null],
        ["leaderboard", null],
        ["home/image", null],
        ["home/social", null],
        ["home/stats", null],
      ]}
      templateLock="all"
      orientation="vertical"
    />
  )
})
